import { Alert, Box, Button, Container, CssBaseline, Divider, Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import logo from '../images/logo.png';
import { util } from 'peerjs';

export default class MyFileHome extends React.Component {
    constructor(props) {
        super(props);
        this.supported = true;

        let browser = util.browser;
        if(browser === 'Not a supported browser.' || browser === 'Not a browser.'){
            this.supported = false;
        }
    }

    selectFiles(event) {
        let files = event.target.files;
        if (files) {
            let nfiles = [];
            for (let i = 0; i < files.length; i++) {
                let f = files.item(i);
                nfiles.push(f);
            }

            this.props.onFileSelected(nfiles);
        }
    }

    openReceivePage(event) {
        this.props.gotoPage && this.props.gotoPage('receive');
    }

    render() {
        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                justifyItems: 'center',
            }}>
                {
                    !this.supported &&
                    <Alert variant='filled' severity='error'>
                        This browser is not supported. Please try to use other browser. - {util.browser}
                    </Alert>
                }
                <img src={logo} alt='HOME' height={200} width={200}></img>
                <Typography variant="body1" sx={{
                    my: 3,
                    mx: 6,
                }} gutterBottom>
                    Use WebRTC for secure and efficient end-to-end file transfer without going through servers.
                </Typography>
                <Grid sx={{my: 5}} container spacing={2}>
                    <Grid item xs={6}>
                        <Button variant='contained' disabled={!this.supported} size='large' fullWidth role={undefined} component='label' onChange={this.selectFiles.bind(this)}>
                            SEND
                            <VisuallyHiddenInput type='file' multiple></VisuallyHiddenInput>
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant='contained' disabled={!this.supported} size='large' fullWidth onClick={this.openReceivePage.bind(this)}>
                            RECEIVE
                        </Button>
                    </Grid>
                </Grid>


            </Box>

        );
    };
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});