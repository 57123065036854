import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0E5FD0',
    },
    secondary: {
      main: '#23A3D5',
    },
    error: {
      main: red.A400,
    },
    background:{
        paper: '#eee'
    }
  },
});

export default theme;