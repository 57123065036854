import React from 'react';
import MyFileHome from './MyFileHome';
import MyFileSender from './MyFileSender';
import MyFileReceiver from './MyFileReceiver';
import { getUrlParams } from '../components/Util';

export default class MyApp extends React.Component {
    constructor(props) {
        super(props);

        this.remotePeerId = getUrlParams("q", document.location.search);
        this.state = {
            files: [],
            toPage: 0, //0: home, 1: send, 2: receive
        }
    }

    componentDidMount(){
        if(this.remotePeerId){
            this.setState({
                toPage: 2
            });
        }
    }


    onFileSelected(files) {
        if (files) {
            this.setState({
                files: files,
                toPage: 1,
            });
        }
    }

    toPage(page) {
        if (page === 'home') {
            this.setState({
                toPage: 0,
                files: [],
            });
        } else if (page === 'send') {
            this.setState({
                toPage: 1,
                files: [],
            });
        } else if (page === 'receive') {
            this.setState({
                toPage: 2,
                files: [],
            });
        }
    }

    render() {
        const homepage = (<MyFileHome onFileSelected={this.onFileSelected.bind(this)} gotoPage={this.toPage.bind(this)}></MyFileHome>);
        const senderpage = (<MyFileSender files={this.state.files} gotoPage={this.toPage.bind(this)}></MyFileSender>);
        const receiverpage = (<MyFileReceiver peerId={this.remotePeerId} gotoPage={this.toPage.bind(this)}></MyFileReceiver>);


        if (this.state.toPage === 0) {
            return homepage;
        }
        if (this.state.toPage === 1) {
            return senderpage;
        }
        if (this.state.toPage === 2) {
            return receiverpage;
        }

        return homepage;

    }
}