import './App.css';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


import MyApp from './pages/MyApp';

import { Container, Grid, Paper } from '@mui/material';


function App() {
 return (
  <Paper sx={{
    bgcolor: 'background.paper',
    boxShadow: 3,
    borderRadius: 3,
    p: 2,
    mx: 'auto',
    minWidth: 300,
    maxWidth: 450,
    minHeight: 550,
    px:3,
    py:3,
  }} elevation={20}>
    <MyApp></MyApp>
  </Paper>
 
 )
}


export default App;
