import { Box, Typography, CircularProgress, Divider } from "@mui/material";

export default function CircularProgressWithLabel(props){
    return (
        <div style={{minWidth: '100%'}}>
            <Box sx={{display: "flex", alignItems: "center", margin: 1, minWidth: '100%'}}>
                <Box sx={{width: '100%', mr: 1}}>
                    <Typography variant="body1">
                        {props.name}
                    </Typography>
                </Box>
                <Box sx={{position: "relative", display: "inline-flex"}}>
                    <CircularProgress variant="determinate" {...props}></CircularProgress>
                    <Box
                        sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}  
                    >
                        <Typography variant="caption" component="div" color="text.secondary">
                            {`${Math.round(props.value)}%`}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Divider></Divider>
        </div>
    );
}