import React from "react";
import { LogEvents, PeerEvents } from "../services/MyFileEvent";
import MyFilePeer from "../services/MyFilePeer";
import { Alert, Backdrop, Box, CircularProgress, Fab, Grid, Snackbar, Typography } from "@mui/material";
import logo from '../images/logo.png';
import CircularProgressWithLabel from '../components/CircularProgressWithLabel';
import { Home } from "@mui/icons-material";

export default class MyFileSender extends React.Component {
    constructor(props) {
        super(props);

        this.logs = [];
        this.state = {
            peerId: '',
            copied: false,
            files: props.files,
            supported: true,
            connecting: true,
            logs: [],
        };
    }

    componentDidMount() {
        this.myFilePeer = new MyFilePeer();
        this.myFilePeer.setOutgingFiles(this.state.files);
        this.myFilePeer.on(PeerEvents.Peer_Opened, (selfId) => {
            this.onLog(selfId);
            this.setState({
                peerId: selfId,
                connecting: false,
            });
        });
        this.myFilePeer.on(PeerEvents.File_Progress, (file, progress, speed) => {
            var oFile = this.state.files.find(f => f.name === file.name);
            oFile.state = 1;
            oFile.progress = progress;
            oFile.speed = speed;

            this.setState({
                files: this.state.files
            });
        });
        this.myFilePeer.on(PeerEvents.File_Sent, (file) => {
            var oFile = this.state.files.find(f => f.name === file.name);
            oFile.state = 2;

            this.onLog(file.name + " sent");
        });
        this.myFilePeer.on(PeerEvents.Peer_Error, (err) => {
            this.setState({
                supported: false,
                connecting: false,
            });
        });
        this.myFilePeer.on(LogEvents.Log_Info, (log) =>{
            this.onLog(log, false);
        });
        this.myFilePeer.on(LogEvents.Log_Err, (log) => {
            this.onLog(log, true);
        });
    }

    onLog(log, isErr){
        if(isErr){
            let myErr = `My_Sender_Err_Log: ${log}`;
            console.error(myErr);
            this.logs.push(myErr);
        }else{
            let myInfo = `My_Sender_Info_Log: ${log}`;
            console.info(myInfo);
            this.logs.push(myInfo);
        }

        this.setState({
            logs: this.logs.reverse()
        });
    }

    gotoHomePage(){
        this.props.gotoPage && this.props.gotoPage('home');
    }

    copyPeerId(){
        navigator.clipboard.writeText(this.state.peerId).then(()=>{
            this.setState({
                copied: true
            });
        });
    }
    handleSnackbarClose(){
        this.setState({
            copied: false
        });
    }

    render() {

        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                justifyItems: 'center',
                position: "relative",
            }}>
                {
                    !this.state.supported &&
                    <Alert variant='filled' severity='error'>
                        This browser is not supported. Please try to use other browser.
                    </Alert>
                }
                <img src={logo} height={200} width={200} alt="File transfer tool" style={{transform: 'rotateX(180deg)'}} sx={{
                    
                }}></img>
                <Typography variant="body1" sx={{
                    mt: 3,
                }} onClick={this.copyPeerId.bind(this)}>
                    {'PEER ID (Click to copy)'}
                </Typography>
                <Typography variant="h6" sx={{
                    mb:6,
                }} onClick={this.copyPeerId.bind(this)}>
                    {this.state.peerId}
                </Typography>
                <Grid>
                    {
                        this.state.files && this.state.files.map((f) => {
                            let p = f.progress ? f.progress : 0;

                            return <CircularProgressWithLabel name={f.name} value={p} key={f.name}></CircularProgressWithLabel>
                        })

                    }
                </Grid>

                <Fab sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                }} size="small" color="primary" onClick={this.gotoHomePage.bind(this)}>
                    <Home></Home>
                </Fab>

                <Backdrop sx={{
                    color: '#fff',
                    zIndex: 9999,
                }} open={this.state.connecting}>
                    <CircularProgress color="inherit"></CircularProgress>
                </Backdrop>

                <Snackbar
                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                    open={this.state.copied}
                    autoHideDuration={1500}
                    message="Copied"
                    onClose={this.handleSnackbarClose.bind(this)}
                ></Snackbar>

                <Typography sx={{
                    maxHeight: 100,
                    overflowY: 'scroll',
                    fontSize: 10,
                    mt: 10
                }} ref={this.logViewerRef}>
                    {
                         this.state.logs && this.state.logs.map((l)=>{
                            return (<>{l} <br/></>)
                          })
                    }
                </Typography>
                
            </Box>
        );
    }
}


