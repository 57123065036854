import React from 'react';
import MyFilePeer from '../services/MyFilePeer';
import { isWechat } from '../components/Util';

import { LogEvents, PeerEvents } from '../services/MyFileEvent';
import CircularProgressWithLabel from '../components/CircularProgressWithLabel';
import { Backdrop, Box, CircularProgress, Grid, TextField, Fab, Typography, Alert} from '@mui/material';
import logo from '../images/logo.png';
import { Home } from "@mui/icons-material";


export default class MyFileReceiver extends React.Component {
    constructor(props) {
        super(props);

        this.isWechat = isWechat();
        this.logs = [];
        this.myFilePeer = null;
        this.logViewerRef = React.createRef();
        this.state = {
            peerId: this.props.peerId,
            connecting: true,
            supported: true,
            files: [],
            log: [],
        }
    }

    componentDidMount() {
        if(this.isWechat) return;

        this.initMyFilePeer();
    }


    initMyFilePeer() {
        this.myFilePeer = new MyFilePeer();
        this.myFilePeer.on(PeerEvents.Peer_Opened, (i) => {
            this.setState({
                connecting: false,
            });
            this.getFileInfos();
        });
        this.myFilePeer.on(PeerEvents.Info_Got, (peerId, files) => {
            this.onLog(files);
            this.setState({
                files: files,
                connecting: false,
            }, () => {
                this.downloadFiles();
            });
        });
        this.myFilePeer.on(PeerEvents.File_Progress, (file, progress, speed) => {
            let iFile = this.state.files.find(f => f.name === file.name);
            iFile.state = 1;
            iFile.speed = speed;
            iFile.progress = progress;
            this.onLog(`${file.name}, Speed: ${speed}; Progress: ${progress}`);

            this.setState({
                files: this.state.files
            });
        });
        this.myFilePeer.on(PeerEvents.File_Got, (file) => {
            let tFile = this.state.files.find(f => f.name === file.name);
            tFile.state = 2;
            this.onLog(`${tFile.name} finished`);

            this.downloadFiles();
        });
        this.myFilePeer.on(PeerEvents.Peer_Error, (err) => {
            this.setState({
                supported: false,
                connecting: false,
            });
        });
        this.myFilePeer.on(LogEvents.Log_Info, (log) =>{
            this.onLog(log, false);
        });
        this.myFilePeer.on(LogEvents.Log_Err, (log) => {
            this.onLog(log, true);
        });
    }

    getFileInfos() {
        if (this.state.peerId) {
            this.setState({
                connecting: true
            });
            this.myFilePeer.requestFileInfos(this.state.peerId);
        }
    }

    downloadFiles() {
        if (this.state.files) {
            var nFile = this.state.files.find(f => !!!f.state);
            if (nFile) {
                nFile.state = 1;
                this.onLog("start to download: " + nFile.name);
                this.myFilePeer.downloadFile(this.state.peerId, nFile);
            }
        }
    }

    onLog(log, isErr){
        if(isErr){
            let myErr = `My_Receiver_Err_Log: ${log}`;
            console.error(myErr);
            this.logs.push(myErr);
        }else{
            let myInfo = `My_Receiver_Info_Log: ${log}`;
            console.info(myInfo);
            this.logs.push(myInfo);
        }
        this.setState({
            logs: this.logs.reverse()
        });
    }

    onPeerIdTyped(event) {
        let value = event.target.value;
        if (value && [...value].length === 4) {
            this.setState({
                peerId: value
            }, () => {
                this.getFileInfos();
            });
        }
    }

    gotoHomePage(){
        this.props.gotoPage && this.props.gotoPage('home');
    }

    viewLogs(){
        let curHeight = this.logViewerRef.maxHeight;
        if(curHeight === 0){
            this.logViewerRef.maxHeight = 150;
        }
        else{
            this.logViewerRef.maxHeight = 0;
        }
    }

    render() {

        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                justifyItems: 'center',
                position: 'relative',
            }}>
                {
                    this.isWechat &&
                    <Alert variant='filled' severity='error'>
                        Cannot receive files in WeChat. Click up right to use your default browser.
                    </Alert>
                }
                {
                    !this.state.supported &&
                    <Alert variant='filled' severity='error'>
                        This browser is not supported. Please try to use other browser.
                    </Alert>
                }
                <img src={logo} height={200} width={200} alt='File transfer tool'></img>

                <TextField disabled={this.isWechat} focused label='INPUT PEER ID' variant='standard' size='medium' sx={{
                    mt: 3,
                    mb: 6,
                }} defaultValue={this.state.peerId} onInput={this.onPeerIdTyped.bind(this)}></TextField>
                <Grid>
                    {
                        this.state.files && this.state.files.map((f) => {
                            let p = f.progress ? f.progress : 0;
                            return (
                                <CircularProgressWithLabel name={f.name} value={p} key={f.name}></CircularProgressWithLabel>
                            );
                        })
                    }
                </Grid>
                <Fab sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                }} size="small" color="primary" onClick={this.gotoHomePage.bind(this)}>
                    <Home></Home>
                </Fab>
                <Backdrop sx={{
                    color: '#fff',
                    zIndex: 9999,
                }} open={!!this.state.connecting}>
                    <CircularProgress color="inherit"></CircularProgress>
                </Backdrop>

                {/* <Chip sx={{
                    mt: 8,
                    mb: 3
                }}
                    label='View Logs' onClick={this.viewLogs.bind(this)}></Chip> */}
                <Typography sx={{
                    maxHeight: 100,
                    overflowY: 'scroll',
                    fontSize: 10,
                    mt: 10
                }} ref={this.logViewerRef}>
                    {
                         this.state.logs && this.state.logs.map((l)=>{
                            return (<>{l} <br/></>)
                          })
                    }
                </Typography>
                
            </Box>
        );
    }

}