
export function getUrlParams(name, str) {
    const reg = new RegExp(`(^|&)${ name}=([^&]*)(&|$)`);
    const r = str.substr(1).match(reg);
    if (r != null) return  decodeURIComponent(r[2]); return null;
}

export function isWechat(){
    return /MicroMessenger/i.test(window.navigator.userAgent);
}

export function getRandom4(){
    let r = 0;
    while(r < 1000){
        r = Math.floor(Math.random() * 10000);
    }
    return r;
}